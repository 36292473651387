import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextWorkFrame = makeShortcode("TextWorkFrame");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`柏木麻里`}</h1>
    <p>{`鎌江一美さんの創作に応答して、柏木麻里さんが「いつも、いつでも」という詩の作品を寄せてくれました。好きな人を想う気持ちの強さと、同時に心の中に感じられる切なさが表現されています。ぜひ、ブックの誌面や、こちらの読み上げで味わってみてください。`}</p>
    <TextWorkFrame type="kashiwagi" mdxType="TextWorkFrame" />
    <Caption before={`作品`} medium={`詩、2020年`} mdxType="Caption">
  《いつも、いつでも》
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/kazumi-kamae`} mdxType="LinkButton">応答する人: 鎌江一美</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      